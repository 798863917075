import React, { useState } from 'react'
import * as style from '../styles/theme/faq.module.scss'

const FaqItem = ({ question, answer }) => {
  const [open, setState] = useState(false)
  const icon = open ? '▲' : '▼';
  return (
    <article className={ style.faq }>
      <h2 onClick={ () => setState(!open) }>{ icon } { question }</h2>
      { open && <p>{ answer }</p> }
    </article>
  )
}

export default FaqItem
