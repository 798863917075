import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import AsideLayout from '../components/AsideLayout'
import SEO from '../components/seo'
import FaqItem from '../components/FaqItem'

const faqs = [
  {
    q: 'Do you have puppies available?',
    a: <span>Check our <Link to="/puppies">puppies page</Link> to see what we have available or what litters are planned or upcoming.</span>
  },
  {
    q: 'How often do you have puppies available?',
    a: 'We only breed our bitches about once every 18 months which means we generally have 1 - 2 litters available each year.'
  },
  {
    q: 'Are there eligibility requirements for adopting one of your pups?',
    a: 'Yes. First of all you need to be at least 18yo and able to sign a legally-binding document. You must have a secure yard (we require photo or video proof). You must be able to provide valid photo ID with name and address and a adoption application form must also be completed and signed. We will also ask you a bunch of questions to ensure you are suitable :) This may sound a bit much, however there are people out there that look to do dogs harm and as ethical breeders we need to do all we can to ensure our dogs go to the right homes with the right people.'
  },
  {
    q: 'Can I go on a waiting list?',
    a: <span>Yes, you have two options: With or without a deposit. If you pass our eligibility requirements and pay a 20% deposit to secure a puppy you go to the front of the waiting list (first in first served). If you choose not to pay a deposit then you will be bumped back on the waiting list by people who pay deposits. If there are more deposits paid than pups born then those who miss out can either have their deposit refunded or they can stay on the waiting list for the next litter. The deposit is not refundable if you decide not to go through with the adoption or if we discover the adoption application was falsified (we have had people lie about their name, address and who was actually going to be owning the puppy). <Link to="contact">Contact us</Link> if you would like to go onto a waiting list.</span>
  },
  {
    q: 'Do you breed "straight back" German Shepherd Dogs?',
    a: 'Yes we do. We strive to breed a more traditional German Shepherd Dog'
  },
  {
    q: 'Do German Shepherds make good family pets? Are they good with children?',
    a: 'Absolutely! German Shepherds make fabulous family pets. They are a very loyal and loving breed and tend to form strong bonds with family members. They generally form a very strong, protective instinct over "their" children. They do however require exercise and mental stimulation and thrive on training, so it is best if at least some family members are active and invest time into the dog.'
  },
  {
    q: 'When will I be able to take my puppy home?',
    a: 'Our pups usually go to their new homes from 10 weeks of age.'
  },
  {
    q: 'Can I use a pet transport company to get my puppy home to me?',
    a: `Forever-home day is already a confusing time for the puppy, as it's removed from its pack (including the humans that cared for it) and into a strange environment (it may be their first car trip) with strange people, strange noises and strange smells. So we prefer that people come and personally pick up and transport their puppies home. It can also be arranged for us to deliver your puppy ourselves or to meet you at a closer location. Both these options will incur an additional fee that will be negotiated on a case-by-case basis.`
  },
  {
    q: 'What comes with my new puppy?',
    a: 'Each puppy comes with food starter pack, collar, vaccinated, wormed, microchipped, vet health check and a lifetime return policy (see next FAQ).'
  },
  {
    q: 'What happens to your breeding stock when they can no longer breed?',
    a: 'All our dogs are a part of our family and stay with us for life. We have no desire to become a large commercial breeder that needs to re-home dogs that are no longer able to breed due to age or other factors.'
  }
]

const FAQ = () => (
  <Layout>
    <AsideLayout
      aside={ <img src="/eva2.jpg" className="rounded shadow" /> }
    >
      <SEO title="FAQ - Frequently Asked Questions" />
      <h1>Frequently Asked Questions</h1>
      { faqs.map((faq) => <FaqItem question={ faq.q } answer={ faq.a } />) }
    </AsideLayout>
  </Layout>
)

export default FAQ
